<template>
    <div class="body">
        <div class="box">
            <div class="box-title">
                <span>试用申请到期提醒</span>
            </div>
            <div class="box-page">
                <div class="box-body">
                    <div class="box-body-content" v-for="(item,i) in testExpired" :key="i" @click="jumpPage(0)">
                        <div class="content">
                            <div class="content-text">
                                <span>订单号：</span>
                                <span>{{item.contractNum}}</span>
                            </div>
                            <div class="content-text">
                                <span>项目：</span>
                                <el-tooltip placement="top" effect="dark" >
                                    <div slot="content" v-html="item.projectName" style="font-size: 18px;width:200px"></div>
                                    <span v-if="item.projectName.length>10">{{ item.projectName.substring(0,10)+'...'  }}</span>
                                    <span v-else aria-disabled>{{ item.projectName.substring(0,10)  }}</span>
                                </el-tooltip>
                            </div>
                            <div class="content-text">
                                <span>测试周期：</span>
                                <span>{{item.startDate}}</span> - <span
                                    style="margin-left: 80px">{{item.endDate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-footer">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalTestPage"
                                :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageTestChange"
                                @handleSizeChange="pageSizeTestChange"/>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="box-title">
                <span>技术服务到期提醒</span>
            </div>
            <div class="box-page">
                <div class="box-body">
                    <div class="box-body-content" v-for="(item,i) in serviceExpired" :key="i" @click="jumpPage(1)">
                        <div class="content">
                            <div class="content-text">
                                <span>订单号：</span>
                                <span>{{item.contractNum}}</span>
                            </div>
                            <div class="content-text">
                                <span>项目：</span>
                                <el-tooltip placement="top" effect="dark" >
                                    <div slot="content" v-html="item.projectName" style="font-size: 18px;width:200px"></div>
                                    <span v-if="item.projectName.length>10">{{ item.projectName.substring(0,10)+'...'  }}</span>
                                    <span v-else aria-disabled>{{ item.projectName.substring(0,10)  }}</span>
                                </el-tooltip>
                            </div>
                            <div class="content-text">
                                <span>测试周期：</span>
                                <span>{{item.startDate}}</span> - <span
                                    style="margin-left: 80px">{{item.endDate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-footer">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalServicePage"
                                :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageServeChange"
                                @handleSizeChange="pageSizeServeChange"/>
                </div>
            </div>
        </div>
        <div class="box" style="margin-right: 0px">
            <div class="box-title">
                <span>发货提醒</span>
            </div>
            <div class="box-page">
                <div class="box-body">
                    <div class="box-body-content" v-for="(item,i) in shippingExpired" :key="i" @click="jumpPage(2)">
                        <div class="content">
                            <div class="content-text">
                                <span>订单号：</span>
                                <span>{{item.contractNum}}</span>
                            </div>
                            <div class="content-text">
                                <span>项目：</span>
                                <el-tooltip placement="top" effect="dark" >
                                    <div slot="content" v-html="item.projectName" style="font-size: 18px;width:200px"></div>
                                    <span v-if="item.projectName.length>10">{{ item.projectName.substring(0,10)+'...'  }}</span>
                                    <span v-else aria-disabled>{{ item.projectName.substring(0,10)  }}</span>
                                </el-tooltip>
                            </div>
                            <div class="content-text">
                                <span>测试周期：</span>
                                <span>{{item.startDate}}</span> - <span
                                    style="margin-left: 80px">{{item.endDate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box-footer">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalShippingPage"
                                :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageShippingChange"
                                @handleSizeChange="pageSizeShippingChange"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //import "swiper/css/swiper.min.css";
    export default {
        data() {
            return {
                items: [],
                pageList: [10, 15, 20],
                option: {
                    pageSize: 10,
                    pageIndex: 1,
                },
                pageType: '',
                totalTestPage: 1,
                totalServicePage: 1,
                totalShippingPage: 1,
                testExpired: [],
                serviceExpired: [],
                shippingExpired: [],
            }
        },
        methods: {
            jumpPage(val) {
                if (val == 0) {
                    this.$router.push('/testApproval')
                } else if (val == 1) {
                    this.$router.push('/contract')
                } else {
                    this.$router.push('/shipping')
                }
            },
            pageTestChange(option) {
                this.option.pageIndex = option;
                this.$api.getSalesIndexPage({
                    applyType: 0,
                    contractType: null,
                    isSendNotice: null,
                    pageSize: 10,
                    pageIndex: option
                }).then(res => {
                    this.testExpired = res.data.content;
                    this.totalTestPage = res.data.totalElements
                })
            },
            pageServeChange(option) {
                this.option.pageIndex = option;
                this.$api.getSalesIndexPage({
                    applyType: 1,
                    contractType: 1,
                    isSendNotice: null,
                    pageSize: 10,
                    pageIndex: option
                }).then(res => {
                    this.serviceExpired = res.data.content;
                    this.totalServicePage = res.data.totalElements
                })
            },
            pageSizeTestChange(option) {
                this.option.pageIndex = option;
                this.$api.getSalesIndexPage({
                    applyType: 0,
                    contractType: null,
                    isSendNotice: null,
                    pageSize: 10,
                    pageIndex: option
                }).then(res => {
                    this.testExpired = res.data.content;
                    this.totalTestPage = res.data.totalElements
                })
            },
            pageSizeServeChange(option) {
                this.option.pageIndex = option;
                this.$api.getSalesIndexPage({
                    applyType: 1,
                    contractType: 1,
                    isSendNotice: null,
                    pageSize: 10,
                    pageIndex: option
                }).then(res => {
                    this.serviceExpired = res.data.content;
                    this.totalServicePage = res.data.totalElements
                })
            },
            pageShippingChange(option) {
                this.option.pageIndex = option;
                this.$api.getSalesIndexPage({
                    applyType: null,
                    contractType: null,
                    isSendNotice: 1,
                    pageSize: 10,
                    pageIndex: option
                }).then(res => {
                    this.shippingExpired = res.data.content;
                    this.totalShippingPage = res.data.totalElements
                })
            },
            pageSizeShippingChange(option) {
                this.$api.getSalesIndexPage({
                    applyType: null,
                    contractType: null,
                    isSendNotice: 1,
                    pageSize: option,
                    pageIndex: 1
                }).then(res => {
                    this.shippingExpired = res.data.content;
                    this.totalShippingPage = res.data.totalElements
                })
            }
        },
        created() {
            this.$api.getSalesIndex({pageSize: 10}).then(
                res => {
                    if (res.code == 200) {
                        this.testExpired = res.data.testData.content;
                        this.totalTestPage = res.data.testData.totalElements
                        this.serviceExpired = res.data.contractData.content;
                        this.totalServicePage = res.data.contractData.totalElements
                        this.shippingExpired = res.data.sendNoticeData.content;
                        this.totalShippingPage = res.data.sendNoticeData.totalElements
                    }
                })
        },
        mounted() {
        }
    }
</script>

<style lang="scss" scoped>
    .body {
        height: 100%;
        width: 100%;
        display: flex;
    }

    .context {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .box {
        height: 100%;
        width: 33%;
        margin-right: 10px;
        background-color: #FFFFFF;
    }

    .box-title {
        width: 100%;
        margin-top: 20px;
        height: 5%;
        text-align: center;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        line-height: 41px;
        color: #437EE6;
        opacity: 1;
    }

    .box-body {
        width: 100%;
        height: 93%;
        //margin:  10px;
        display: flex;
        justify-content: space-between;
        align-content: flex-start;
        flex-wrap: wrap;
        padding: 0 15px;
        box-sizing: border-box;
    }

    .box-page {
        height: 90%;
    }

    .box-body-content {
        width: 47%;
        height: 18.5%;
        //margin-right: 10px;
        //height: 100%;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        opacity: 1;
        border-radius: 5px;
        margin: 5px;
    }

    .box-footer {
        height: 4%;
        margin-top: 4%;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .content {
        font-size: 16px;
        font-family: Source Han Sans CN;
        cursor: pointer;
        font-weight: 400;
        line-height: 27px;
        color: #4D4D4D;
        overflow-y: hidden;
        opacity: 1;
    }


</style>
